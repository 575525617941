import React, { useState, useEffect } from 'react';
import RecipeList from './RecipeList';
import '../css/app.css'
import uuidv4 from 'uuid/v4'
import RecipeEdit from './RecipeEdit';

// Make this context available to all components, that import 'App'
export const RecipeContext = React.createContext()

// Key to identify our recipes in local storage
const LOCAL_STORAGE_RECIPES_KEY = 'cookingWithReact.recipes'

function App() {
  // Define state variables and the methods used to update them
  const [selectedRecipeId, setSelectedRecipeId] = useState()
  const [recipes, setRecipes] = useState(sampleRecipes)

  // Try to get the selected recipe, every time the app is rendered (every time a state variable changes).
  const selectedRecipe = recipes.find(recipe => recipe.id === selectedRecipeId)

  // Provide the objects that should be accessible to other components
  const recipeContextValue = { handleRecipeAdd, handleRecipeDelete, handleSelectRecipe, handleRecipeChange}

  // Run this code once at start
  useEffect(() => {
    const recipesJSON = localStorage.getItem(LOCAL_STORAGE_RECIPES_KEY)
    if (recipesJSON != null) setRecipes(JSON.parse(recipesJSON))
  }, [])

  // Run this code every time 'recipes' is updated
  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_RECIPES_KEY, JSON.stringify(recipes))
  }, [recipes])

  // Handler for buttons, that select a recipe to edit
  function handleSelectRecipe(id) {
    setSelectedRecipeId(id)
  }

  // Handler for buttons, that add a new recipe
  function handleRecipeAdd() {
    const newRecipe = {
      id: uuidv4(),
      title: '',
      cookTime: "",
      servings: 1,
      instructions: "",
      ingredients: [
        {
          id: uuidv4(),
          name: '',
          amount: ''
        }
      ]
    }
    setRecipes([...recipes, newRecipe]) // Spread/copy the existing recipes to the array and add the new one.
    setSelectedRecipeId(newRecipe.id) // Open this for editing when created
  }

  // Handler for the delete buttons of each recipe
  function handleRecipeDelete(id) {
    // If the selected recipe gets deleted, set the selected to undefined
    if (selectedRecipeId != null && selectedRecipeId === id) {
      setSelectedRecipeId(undefined)
    }

    // Filter out the recipe with the given id (only return those elements with 'true' as a result)
    setRecipes(recipes.filter(recipe => recipe.id !== id))
  }

  // Handler for changing the recipe with the given id to the new recipe provided.
  function handleRecipeChange(id, recipe) {
    const newRecipes = [...recipes]
    const index = newRecipes.findIndex(r => r.id === id)
    newRecipes[index] = recipe
    setRecipes(newRecipes)
  }

  return (
    <RecipeContext.Provider value={recipeContextValue}> {/* All the components, that want to access the RecipeContext need to be nested within this ContextProvider. */}
      <RecipeList recipes={recipes} />
      { selectedRecipe && <RecipeEdit recipe={selectedRecipe} />} {/* This syntax only renders the RecipeEdit component, when the expression before && is true */}
    </RecipeContext.Provider>
  )
  
}

// Initial data
const sampleRecipes = [
  {
    id: 1,
    title: "Plain Chicken",
    cookTime: "1:45",
    servings: 3,
    instructions: "1. Put salt on chicken\n2. Put chicken in oven\n3. Eat chicken",
    ingredients: [
      {
        id: 1,
        name: "Chicken",
        amount: "2 Pounds"
      },
      {
        id: 2,
        name: "Salt",
        amount: "1 Tbs"
      }
    ]
  },
  {
    id: 2,
    title: "Plain Pork",
    cookTime: "0:45",
    servings: 5,
    instructions: "1. Put paprika on pork\n2. Put pork in oven\n3. Eat pork",
    ingredients: [
      {
        id: 1,
        name: "Pork",
        amount: "3 Pounds"
      },
      {
        id: 2,
        name: "Paprika",
        amount: "2 Tbs"
      }
    ]
  }
]

export default App;
