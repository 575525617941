import React, { useContext, useState } from 'react'
import Recipe from "./Recipe"
import { RecipeContext } from './App'
import SearchBar from './SearchBar'

export default function RecipeList({recipes}) { // Props are passed in as an object.

    // Set a state variable for the search term
    const [searchTerm, setSearchTerm] = useState()

    // Make the RecipeContext available here
    const {handleRecipeAdd} = useContext(RecipeContext)

    // Check if the ingredients of a recipe contain the search term
    function recipeIngredientsContain(recipe, searchTerm) {
        let result = false
        recipe.ingredients.forEach( ingredient => {
            if ( ingredient.name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1 || ingredient.amount.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1 ) {
                result = true
            }
        })

        return result
    }

    return (
        <>
            <SearchBar handleSearchTermChange={setSearchTerm} />
            <div className="recipe-list">
                <div>
                    {
                        // Render an component for each recipe with a unique key (important) and pass in all the props of each recipe.
                        // eslint-disable-next-line
                        recipes.map(recipe => {
                            if (searchTerm == null 
                                || searchTerm === '' 
                                || recipe.cookTime.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
                                || recipe.title.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
                                || recipe.instructions.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
                                || recipeIngredientsContain(recipe, searchTerm)) {
                                return (
                                    <Recipe key={recipe.id} {...recipe} />
                                )
                            }
                            
                        })
                    }
                </div>
                <div className="recipe-list__add-recipe-button-container">
                    <button className="btn btn--primary" onClick={handleRecipeAdd}>Add recipe</button>
                </div>
            </div>
        </>
    )
}
