import React, { useContext } from 'react'
import IngredientList from './IngredientList'
import { RecipeContext } from './App'

export default function Recipe(props) {

    // Destructure the props here for better readability of many props
    const {id, title, cookTime, servings, instructions, ingredients} = props

    // Make the RecipeContext available here
    const {handleRecipeDelete, handleSelectRecipe} = useContext(RecipeContext)
    
    return (
        <div className="recipe">
            <div className="recipe__header">
                <h3 className="recipe__title">{title}</h3>
                <button className="btn btn--primary mr-1" onClick={() => handleSelectRecipe(id)}>Edit</button>
                <button className="btn btn--danger" onClick={() => handleRecipeDelete(id)}>Delete</button>
            </div>
            <div>
                <div className="recipe__row">
                    <span className="recipe__label">Cook Time:</span>
                    <span className="recipe__value">{cookTime}</span>
                </div>
                <div className="recipe__row">
                    <span className="recipe__label">Servings:</span>
                    <span className="recipe__value">{servings}</span>
                </div>
                <div className="recipe__row">
                    <span className="recipe__label">Instructions:</span>
                    <div className="recipe__value recipe__value--indented recipe__instructions">
                        {instructions}
                    </div>
                </div>
                <div className="recipe__row">
                    <span className="recipe__label">Ingredients:</span>
                    <div className="recipe__value recipe__value--indented">
                        <IngredientList ingredients={ingredients} />
                    </div>
                </div>
            </div>
        </div>
    )
}
