import React from 'react'
import Ingredient from './Ingredient'

export default function IngredientList({ingredients}) {
    const ingredientElements = ingredients.map(ingredient => {
        // Return an Ingredient component for each ingredient with a unique key (important) and pass in all the props of each ingredient.
        return <Ingredient key={ingredient.id} {...ingredient} />
    })

    return (
        <div className="ingredient-grid">
            {ingredientElements}
        </div>
    )
}
