import React, { useContext } from 'react'
import RecipeIngredientEdit from './RecipeIngredientEdit'
import { RecipeContext } from './App'
import uuidv4 from 'uuid/v4'

export default function RecipeEdit({ recipe }) { // Props are passed in as an object.

    // Make the RecipeContext available here
    const {handleRecipeChange, handleSelectRecipe} = useContext(RecipeContext)

    // Helper function that takes an object of new values and mixes those in to the existing ones.
    function handleChange(changes) {
        const newRecipe = {...recipe, ...changes}
        handleRecipeChange(recipe.id, newRecipe)
    }

    // Replaces the ingredient with the given id with the one passed in
    function handleIngredientChange(id, ingredient) {
        const newIngredients = [...recipe.ingredients]
        const index = newIngredients.findIndex(i => i.id === id)
        newIngredients[index] = ingredient

        // Since we changed the ingredients of a recipe, the recipe changed too. Let it know.
        handleChange({ingredients: newIngredients})
    }

    // Handles adding of a new ingredient
    function handleIngredientAdd() {
        const newIngredient = {
            id: uuidv4(),
            name: '',
            amount: ''
        }

        // Since we changed the ingredients of a recipe, the recipe changed too. Let it know.
        handleChange({ingredients: [...recipe.ingredients, newIngredient]})
    }

    // Handle the deletion of an ingredient
    function handleIngredientDelete(id) {
        // Since we changed the ingredients of a recipe, the recipe changed too. Let it know.
        handleChange({ ingredients: recipe.ingredients.filter(i => i.id !== id) })
    }

    return (
        <div className="recipe-edit">
            <div className="recipe-edit__remove-button-container">
                <button className="btn recipe-edit__remove-button" onClick={() => handleSelectRecipe(undefined)}>&times;</button>
            </div>
            <div className="recipe-edit__details-grid">
                <label className="recipe-edit__label" htmlFor="name">Name</label>
                <input className="recipe-edit__input" type="text" name="name" id="name" value={recipe.title} onChange={e => handleChange({ title: e.target.value })} />
                <label className="recipe-edit_label" htmlFor="cookTime">Cook Time</label>
                <input className="recipe-edit__input" type="text" name="cookTime" id="cookTime" value={recipe.cookTime} onChange={e => handleChange({ cookTime: e.target.value })}  />
                <label className="recipe-edit_label" htmlFor="servings">Servings</label>
                <input className="recipe-edit__input" type="number" name="servings" id="servings" value={recipe.servings} onChange={e => handleChange({ servings: parseInt(e.target.value) || '' })}  />
                <label className="recipe-edit_label" htmlFor="instructions">Instructions</label>
                <textarea className="recipe-edit__input" name="instructions" id="instructions" value={recipe.instructions} onChange={e => handleChange({ instructions: e.target.value })} />
            </div>
            <br />
            <label className="recipe-edit__label">Ingredients</label>
            <div className="recipe-edit__ingredient-grid">
                <div>Name</div>
                <div>Amount</div>
                <div></div>
                {
                    // Render an component for each ingredient with a unique key (important) and pass in the ingredient and the necessary button-handler-functions. We could also create a new context for those functions, or integrate them in the existing one.
                    recipe.ingredients.map(
                        ingredient => (
                            <RecipeIngredientEdit key={ingredient.id} ingredient={ingredient} handleIngredientChange={handleIngredientChange} handleIngredientDelete={handleIngredientDelete} />
                        )
                    )
                }
            </div>
            <div className="recipe-edit__add-ingredient-btn-container">
                <button className="btn btn--primary" onClick={e => handleIngredientAdd()}>Add Ingredient</button>
            </div>
        </div>
    )
}
